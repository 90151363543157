import React from 'react';
import { graphql } from 'gatsby';
import propTypes from 'prop-types';

import SEO from '../../components/seo';

import Contact from '../../components/contact';
import Hero from '../../components/hero';
import Layout from '@src/components/layout';
import MarkdownContent from '../../components/markdown-content/MarkdownContent';

const KortTerms = ({ data }) => {
  const {
    contentfulPage: {
      headLine,
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
      pageHeaderSubtitle: { pageHeaderSubtitle },
    },
  } = data;

  return (
    <Layout newStyle>
      <SEO title={headLine} />
      <Hero title={pageHeader} subtitleMarkdown={pageHeaderDescription} />
      <div className="container">
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <MarkdownContent markdown={pageHeaderSubtitle} />
        </div>
      </div>
      <Contact hideFaq />
    </Layout>
  );
};

export default KortTerms;

KortTerms.propTypes = {
  data: propTypes.object,
};

export const query = graphql`
  query {
    contentfulPage(pageUrl: { eq: "/allmanna-villkor/kort" }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }

      pageHeaderSubtitle {
        pageHeaderSubtitle
      }

      pageContent {
        ... on ContentfulDescriptionComponent {
          id
          bodyText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
